import service from "./interceptor";
import { showTips } from "@/utils/antd-pro";
import { getAppId } from "@/utils/index";

function request(url, params, options, baseConfig, method) {
  return new Promise((resolve, reject) => {
    let data = {};

    if (method === "get") data = { params };
    if (method === "post" || method === "put") data = { data: params };
    const config = {
      url: url.replace(/\{app-id\}/g, getAppId()),
      method,
      ...data,
      ...baseConfig,
      baseUrl: process.env.REACT_APP_NOTAUTH_BASEURL,
      signal: params?.signal,
    };

    service(config)
      .then((res) => resolve(res))
      .catch((error) => {
        if (options && options.error) showTips(error.msg);
        reject(error);
      });
  });
}

// 封装GET请求
export function get(url, data) {
  const { params, options, config } = data || {};
  return request(url, params, options, config, "get");
}
// 封装POST请求
export function post(url, data) {
  const { params, options, config } = data || {};

  return request(url, params, options, config, "post");
}
// 封装PUT请求
export function put(url, data) {
  const { params, options, config } = data || {};

  return request(url, params, options, config, "put");
}
// 封装DELETE请求
export function del(url, data) {
  const { params, options, config } = data || {};

  return request(url, params, options, config, "delete");
}

export default {
  get,
  post,
  put,
  del,
};
