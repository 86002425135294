const routes = [
    {
        path: "/qw/studentProfile",
        component: () => import("@/views/QwSidebar/StudentProfile/studentList"),
        key: "/qw/studentProfile",
        needLogin: true,
        tabbar: false,
        title: "菱镜学员",
        safeArea: false,
    },
    {
        path: "/qw/studentDetail",
        component: () => import("@/views/QwSidebar/StudentProfile/studentDetail"),
        key: "/qw/studentDetail",
        needLogin: true,
        tabbar: false,
        title: "菱镜学员",
        safeArea: false,
    },
    {
        path: "/qw/shareDetail",
        component: () => import("@/views/QwSidebar/StudentProfile/shareDetail"),
        key: "/qw/shareDetail",
        needLogin: false,
        tabbar: false,
        title: "菱镜学员",
        safeArea: false,
    }
];

export default routes;
