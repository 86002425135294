import * as ww from "@wecom/jssdk";
import { httpGetCropTicket } from "@/api/common";

// 企微开发文档  https://developer.work.weixin.qq.com/document/path/98560

// const JSAPI_TICKET =
//   "O3SMpm8bG7kJnF36aXbe81k0xtZyl6eJ7VLmVk-aIjne3sidhhVinQatQL3bHRi0YTx3Oay6rHBPNebB9eEhkA";

// 生成企业签名的回调函数
export const getConfigSignature = async () => {
  const url = window.location.href.split("#")[0];
  const encodeUrl = encodeURIComponent(url);
  console.log(encodeUrl, "encodeUrl");
  // console.log(
  //   "ww.getSignature(JSAPI_TICKET) :>> ",
  //   ww.getSignature(JSAPI_TICKET)
  // );
  try {
    const res = await httpGetCropTicket();
    if (res.success) {
      return ww.getSignature(res.data);
    }
  } catch (error) {
    console.log("error :>> ", error);
  }

  return ww.getSignature(
    "O3SMpm8bG7kJnF36aXbe81k0xtZyl6eJ7VLmVk-aIjmFZ0awnl2cx6oHysy6npGL4iSYQcCK5MvMk9T3ZkDj7Q"
  );
  //     // 根据 url 生成企业签名
  //     // 生成方法参考 https://developer.work.weixin.qq.com/document/path/90539
  //     return { timestamp, nonceStr, signature }
};

// 隐藏菜单项
export const hideMenus = () => {
  ww.hideMenuItems({
    menuList: [
      "menuItem:copyUrl", // 复制链接
      "menuItem:openWithSafari", // 收藏
    ],
  });
};

// 注册应用身份信息
export const wxRegister = () => {
  const debug = process.env.APP_ENV !== "prod";
  console.log(debug, "debug");
  ww.register({
    debug,
    corpId: "ww67969ef74da54bfb", // 必填，当前用户企业所属企业ID
    jsApiList: [
      "onMenuShareAppMessage",
      "onMenuShareWechat",
      "hideAllNonBaseMenuItem",
      "startRecord",
      "stopRecord",
      "hideMenuItems",
      "chooseImage",
      "uploadImage",
      "downloadImage",
      "previewImage",
      "getLocalImgData",
      "imagePreview",
    ], // 必填，需要使用的JSAPI列表
    onConfigSuccess: () => {
      hideMenus();
      // 配置成功的回调
      console.log("配置成功");
    },
    onConfigFail: () => {
      // 配置失败的回调
      console.log("配置失败");
    },
    getConfigSignature, // 必填，根据url生成企业签名的回调函数
  });
};

/**
 * 获取「转发」按钮点击状态自定义分享内容
 * @param {title}  分享标题
 * @param {desc}  分享描述
 * @param {link}  分享链接(在微信上分享时，该链接的域名必须与企业某个应用的可信域名一致)
 * @param {imgUrl}  分享图标
 * @param {success}  成功回调
 * @param {fail}  失败回调
 * @param {cancel}  取消回调
 * @param {complete}  完成回调
 */
export const shareApp = ({
  title = "",
  desc = "",
  link = "",
  imgUrl = "",
  success = () => {},
  cancel = () => {},
  fail = () => {},
  complete = () => {},
}) => {
  ww.onMenuShareAppMessage({
    title,
    desc,
    link,
    imgUrl,
    success() {
      // 用户确认分享后回调
      success && success();
    },
    cancel() {
      // 用户取消分享后回调
      cancel && cancel();
    },
    fail() {
      // 用户分享失败后回调
      fail && fail();
    },
    complete() {
      // 用户分享完成后回调
      complete && complete();
    },
  });
};

/**
 * 获取「微信」按钮点击状态并自定义分享内容。
 * @param {title}  分享标题
 * @param {desc}  分享描述
 * @param {link}  分享链接(在微信上分享时，该链接的域名必须与企业某个应用的可信域名一致)
 * @param {imgUrl}  分享图标
 * @param {success}  成功回调
 * @param {fail}  失败回调
 * @param {cancel}  取消回调
 * @param {complete}  完成回调
 */
export const shareWechat = ({
  title = "",
  desc = "",
  link = "",
  imgUrl = "",
  success = () => {},
  cancel = () => {},
  fail = () => {},
  complete = () => {},
}) => {
  ww.onMenuShareWechat({
    title,
    desc,
    link,
    imgUrl,
    success() {
      // 用户确认分享后回调
      success && success();
    },
    cancel() {
      // 用户取消分享后回调
      cancel && cancel();
    },
    fail() {
      // 用户分享失败后回调
      fail && fail();
    },
    complete() {
      // 用户分享完成后回调
      complete && complete();
    },
  });
};

export const shareInfo = (param) => {
  // 注册
  wxRegister();
  //   转发
  shareApp(param);
  //   微信
  shareWechat(param);
  //   隐藏所有非基础按钮
  ww.hideAllNonBaseMenuItem();
  //   显示按钮  群发功能&朋友圈暂不支持隐藏
  ww.showMenuItems({
    menuList: [
      "menuItem:share:appMessage", // 转发
      "menuItem:share:wechat", // 微信
      // "menuItem:copyUrl", // 复制链接
      "menuItem:favorite", // 收藏
    ],
  });
};

// export const chooseImage = async () => {
//   console.log("选择图片");
// };

// 录音
export const startRecord = () => {
  ww.startRecord();
};
