import home from "./home";
import report from "./report";
import order from "./order";
import cash from "./cash";
import visit from "./visit";
import sidebar from './sidebar';
import tool from "./tool";

const routers = [...home, ...report, ...order, ...cash, ...visit, ...tool, ...sidebar];

export default routers;
