const routes = [
  {
    path: "/tools/uploadImg",
    component: () => import("@/views/UploadImg"),
    key: "/tools/uploadImg",
    tabbar: false,
    title: "上传图片",
    safeArea: false,
  },
];

export default routes;
